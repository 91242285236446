import React, {useEffect, useRef, useState} from "react";
import {Linking, StyleSheet, View} from "react-native";
import {Button, Image, Modal, Text, VStack} from "native-base";
import apps from "./lib/apps";
import {ShopTblData, UserData, YourOrder} from "./lib/model";
import * as remote from "./lib/remote";
import {useRecoilState} from "recoil";
import {orderState} from "./state/orderState";
import {v4 as uuidv4} from "uuid";

/**
 * Splash screen
 * 1.5초 유지후 url에서 shopId와 tableNo를 추출해서 저장하고 메인 스택으로 넘어간다.
 * UR: /:shopId/:tableNo
 */
function Splash({navigation}) {
    const [yourOrder, setYourOrder] = useRecoilState(orderState);
    const [start, setStart] = useState(false);

    const [errorMessage, setErrorMessage] = useState("");        // 에러메시지
    const [errorVisible, setErrorVisible] = useState(false);        // 에러 모달
    const timerRef = useRef(null); // Store the interval reference
    const hasStopped = useRef(false); // Prevent multiple interval stops

    /**
     * uuid를 생성하고 localStorage에 저장한다. 기존에 저장된 localStorage가 없으면 새로 생성한다.
     */
    function getUuid() {
        let uuid = localStorage.getItem("uuid");
        if (uuid == undefined) {
            uuid = uuidv4();
            //console.log(`Generated new uuid: ${uuid}`)

            localStorage.setItem("uuid", uuid);
        }
        //console.log(`uuid: ${uuid}`)
        return uuid;
    }

    useEffect(() => {
        if (start) {
            navigation.replace("AppStack");
        }

        const getUrl = async () => {

            if (hasStopped.current) return; // Stop execution if the process is already stopped

            const url = new URL(await Linking.getInitialURL());
            if (!url) return;

            const path = url.search.replace("?", "").split("/");

            //console.log(path);
            if (path.length < 5) {
                //alert("Your session has expired. Please scan the table QR code again: " + path);
                setErrorMessage("Your session has expired. Please scan the table QR code again");
                setErrorVisible(true);
                if (!hasStopped.current) {
                    clearInterval(timerRef.current); // Stop interval only once
                    hasStopped.current = true; // Prevent further calls
                }
                return;
                //throw Error("Your session has expired. Please scan the table qr code again");

            }

            let uuid = getUuid();

            apps.shopId = path[0];
            apps.replaceShopId();

            apps.tableNo = path[1];
            apps.txId = Number(path[2]);
            apps.signature = path[3];
            apps.profile = path[4];
            if (path[5]) apps.ipLast = path[5];

            //console.log("shopId: " + apps.shopId);
            //console.log("tableNo: " + apps.tableNo);
            //console.log("TX Id: " + apps.txId);
            //console.log("Signature: " + apps.signature);
            //console.log("Profile: " + apps.profile);
            //console.log("ipLast: " + apps.ipLast);

            const shop: ShopTblData = await remote.getShop(apps.shopId, apps.tableNo);
            //console.log("shop: " + shop);
            apps.currentShop = shop;
            apps.qrType = shop.qrType;

            //console.log(shop);

            const user: UserData = await remote.findUser(uuid);
            //console.log("userId: " + user.id);
            apps.user = user;
            apps.name = localStorage.getItem("name") || "";
            apps.mobile = localStorage.getItem("mobile") || "";

            if (apps.signature != shop.signature) {
                const message = "You are currently using an invalid QR Code. Please contact a staff member for assistance.";
                //alert(message);
                //throw new Error(message);
                setErrorMessage(message);
                setErrorVisible(true);
                if (!hasStopped.current) {
                    clearInterval(timerRef.current); // Stop interval only once
                    hasStopped.current = true; // Prevent further calls
                }
                return;
            }

            if (shop.status !== "ACTIVE") {
                let message = "QR System is currently Offline";
                if (shop.status == "QROFF") {
                    /* 매장은 영업중이지만, QR만 OFF하고 싶을때 사용함 */
                    message = "QR System is currently Offline. Please use an alternative system.";

                }
                if (shop.status == "INACTIVE") {
                    /* 매장이 공휴일 혹은 사정상 임시로 하루 혹은 몇일동안 문을 닫았을때 */
                    message = shop.name + " is currently closed. Please visit during our next business hours.";
                }
                //alert(message);
                //throw new Error(message);
                setErrorMessage(message);
                setErrorVisible(true);
                if (!hasStopped.current) {
                    clearInterval(timerRef.current); // Stop interval only once
                    hasStopped.current = true; // Prevent further calls
                }
                return;
            }


            const now = new Date();
            const dayOfWeek = now.getDay(); // 0 = Sunday, 1 = Monday, ..., 6 = Saturday
            let openingTime, closingTime;

            switch (dayOfWeek) {
                case 0: // Sunday
                    openingTime = shop.sunOpeningTime;
                    closingTime = shop.sunClosingTime;
                    break;
                case 1: // Monday
                    openingTime = shop.monOpeningTime;
                    closingTime = shop.monClosingTime;
                    break;
                case 2: // Tuesday
                    openingTime = shop.tueOpeningTime;
                    closingTime = shop.tueClosingTime;
                    break;
                case 3: // Wednesday
                    openingTime = shop.wedOpeningTime;
                    closingTime = shop.wedClosingTime;
                    break;
                case 4: // Thursday
                    openingTime = shop.thuOpeningTime;
                    closingTime = shop.thuClosingTime;
                    break;
                case 5: // Friday
                    openingTime = shop.friOpeningTime;
                    closingTime = shop.friClosingTime;
                    break;
                case 6: // Saturday
                    openingTime = shop.satOpeningTime;
                    closingTime = shop.satClosingTime;
                    break;
                default:
                    throw new Error("Invalid day of the week");
            }

            // const formatTime = (timeArray) => {
            //     const [hours, minutes, seconds] = timeArray;
            //     return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}`;
            // };

            const formatTime = (timeArray) => {
                if (!Array.isArray(timeArray) || timeArray.length < 2) {
                    console.error('Invalid timeArray:', timeArray);
                    return '00:00';
                }
                const [hours, minutes] = timeArray;
                return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}`;
            };

            const formattedOpeningTime = formatTime(openingTime);
            const formattedClosingTime = formatTime(closingTime);

            // Check if opening time and closing time are the same
            if (formattedOpeningTime === formattedClosingTime) {
                const message = `${shop.name} is closed today. Please visit us tomorrow.`;
                //alert(message);
                //throw new Error(message);
                setErrorMessage(message);
                setErrorVisible(true);
                if (!hasStopped.current) {
                    clearInterval(timerRef.current); // Stop interval only once
                    hasStopped.current = true; // Prevent further calls
                }
                return;
            }

            const isOperating = apps.isBettwen(now, openingTime, closingTime)
            //console.log("Is the shop currently operating? " + isOperating);
            if (!isOperating) {
                const message = `${shop.name} is currently closed. Please visit during operating hours: ${formattedOpeningTime} - ${formattedClosingTime}.`;
                //alert(message);
                //throw new Error(message);
                setErrorMessage(message);
                setErrorVisible(true);
                if (!hasStopped.current) {
                    clearInterval(timerRef.current); // Stop interval only once
                    hasStopped.current = true; // Prevent further calls
                }
                return;
            }

            setYourOrder(new YourOrder(apps.shopId, apps.qrType, apps.tableNo, apps.txId));
            setStart(true);
            clearInterval(timerRef.current); // Stop the interval after success
        };

        timerRef.current = setInterval(async () => {
            await getUrl();
        }, 1500);

        return function cleanup() {
            clearInterval(timerRef.current);
            setStart(false);
        };
    })

    return (
        <View style={styles.container}>
            <VStack alignItems="center">
                <Image mt={100} h={190} w={190} alt="logo" source={require("../assets/splash_logo.png")}></Image>
                <Text mt={-3} style={styles.version}>VERSION 1.0</Text>

                <Image mt={250} w={150} h={20} resizeMode="contain" alt="medianara" source={require("../assets/medianara.png")}></Image>
                <Modal isOpen={errorVisible} onClose={() => setErrorVisible(false)}>
                    <Modal.Content>
                        <Modal.Header>Message</Modal.Header>
                        <Modal.Body>
                            <Text>{errorMessage}</Text>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button style={{width: "100px"}} onPress={() => setErrorVisible(false)}> OK </Button>
                        </Modal.Footer>
                    </Modal.Content>
                </Modal>
            </VStack>
        </View>
    );
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: '#f7f5f2',
        alignItems: 'center',
        justifyContent: 'center',
    },
    version: {
        fontSize: 11,
        color: "#db146d",
        fontWeight: "bold",
        fontFamily: "NotoSans-Black",
    }
});

export default Splash;

