import {Box, Button, HStack, Icon, Image, Modal, Pressable, Toast, VStack} from "native-base";
import React, {useEffect, useState} from "react";
import {
    Dimensions,
    FlatList,
    Platform,
    StyleSheet,
    Text,
    TextInput,
    TouchableWithoutFeedback,
    View
} from "react-native";
import { WebView } from "react-native-webview";
import apps, {Happyhour, SaleMode} from "./lib/apps";
import {
    CateMenuAndOptionData,
    mapToItems,
    PayMethod,
    Receipt,
    ReceiptRes,
    ShopTblData,
    tagOptions,
    YourOrder
} from "./lib/model";
import {useRecoilState, useRecoilValue} from "recoil";
import {cartState} from "./state/cartState";
import DashedLine from "react-native-dashed-line";
import {orderState} from "./state/orderState";
import {useMutation, useQuery} from "@tanstack/react-query";
import {Order, OrderItem, OrderStatusRes} from "./lib/order_model";
import * as remote from "./lib/remote";
import {SubmitIndicator} from "./component/loading";
import { Ionicons } from '@expo/vector-icons'; // Assuming you are using Ionicons

function Title({navigation}) {
    return (
        <HStack style={styles.titleContainer}>
            <TouchableWithoutFeedback onPress={() => {
                navigation.pop();
            }}>
                <Image source={require("../assets/back.png")} style={styles.titleBack} alt="back"/>
            </TouchableWithoutFeedback>
            <Text style={styles.title}>Order Details</Text>
        </HStack>
    );
}

function Table() {
    const yourOrder = useRecoilValue(orderState);

    return (
        <Box style={styles.tableContainer}>
            <Text style={styles.tableText}>{yourOrder.getOrderInfoLong()}</Text>
            <Image source={require("../assets/paperbottom.png")} alt="pb" h="18px" w="100%"/>
        </Box>
    );
}

function Header({shop}: { shop: ShopTblData }) {
    return (
        <VStack style={styles.container}>
            <Box style={styles.headerTop}>
                <Text style={styles.shopName}>{shop.name}</Text>
                <Text style={styles.showAddress}>{shop.address}</Text>
            </Box>
            {/*<HStack style={styles.headerBottom}>
        <Text style={styles.pickupTimeLabel}>Pickup time</Text>
        <Text style={styles.pickupTime}>5-15 min</Text>
      </HStack>*/}
        </VStack>
    );
}

function Total() {
    const cart = useRecoilValue(cartState);
    const yourOrder = useRecoilValue(orderState);
    const isCreditCard = yourOrder.payMethod === PayMethod.CREDIT_CARD;
    const shop = apps.currentShop;

    // SubTotal and Fee Calculation
    const subTotal = cart.total();

    //for domestic credit, debit, eftpos & Amex cards => 1.65% + $0.30
    //for international.... 2.75% + %0.30
    const fee = isCreditCard ? (subTotal * shop.cnpSurchargeRate / 100) + shop.cnpFixedTransactionFee : 0.0;
    const total = subTotal + fee;

    // State to handle the modal visibility
    const [isModalVisible, setModalVisible] = useState(false);

    //console.log("yourOrder.payMethod: " + yourOrder.payMethod);

    return (
        <VStack style={styles.totalContainer}>
            <HStack style={styles.totalRow}>
                <Text style={styles.totalLabel}>SubTotal</Text>
                <Text style={styles.totalPrice}>${subTotal.toFixed(2)}</Text>
            </HStack>
            <HStack style={styles.totalRow}>
                <Text style={styles.totalLabel}>Processing Fee</Text>

                {/* Pressable Question Mark Icon */}
                {isCreditCard && (
                    <Pressable onPress={() => setModalVisible(true)} style={{ marginLeft: 5, marginRight: 5 }}>
                        <Icon as={Ionicons} name="ios-help-circle-outline" size={5} color="gray.500" />
                    </Pressable>
                )}
                <Text style={styles.totalPrice}>
                    ${fee.toFixed(2)}
                </Text>
            </HStack>
            {isCreditCard && (
                <HStack style={{ flexDirection: 'row', justifyContent: 'flex-end', width: '100%' }}>
                    <Text style={{ color: 'red' }}>
                        * International Card will be different
                    </Text>
                </HStack>
            )}
            <HStack style={styles.totalRowLast}>
                <Text style={styles.totalLabel}>Total</Text>
                <Text style={styles.totalPrice}>${total.toFixed(2)}</Text>
            </HStack>

            {/* Modal for Fee Explanation */}
            <Modal isOpen={isModalVisible} onClose={() => setModalVisible(false)}>
                <Modal.Content>
                    <Modal.Header>Processing Fee Calculation</Modal.Header>
                    <Modal.Body>
                        <Text>The processing fee is calculated as:</Text>
                        <Text>- {shop.cnpSurchargeRate}% (GST included) of the total amount</Text>
                        <Text>- A fixed transaction fee of ${shop.cnpFixedTransactionFee.toFixed(2)} (GST included) added to every transaction</Text>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button onPress={() => setModalVisible(false)}>Close</Button>
                    </Modal.Footer>
                </Modal.Content>
            </Modal>

        </VStack>
    );
}

class PayMethods {
    methods: Map<PayMethod, Boolean> = new Map<PayMethod, Boolean>([
        [PayMethod.CREDIT_CARD, false],
        [PayMethod.PAYPAL, false],
        [PayMethod.STRIPE, false],
        [PayMethod.APPLE_PAY, false],
        [PayMethod.PAY_AT_COUNTER, false],
    ]);

    constructor() {
    }

    paymentOptionStyle(method: PayMethod) {
        return this.methods.get(method) ? styles.paymentOptionSelected : styles.paymentOption;
    }

    paymentOptionTextStyle(method: PayMethod) {
        return this.methods.get(method) ? styles.paymentOptionTextSelected : styles.paymentOptionText;
    }

    select(method: PayMethod) {
        if (this.methods.get(method)) return this;  // on인 상태에서는 터치를 해도 변하지 않도록 this를 리턴

        const newMethods = new PayMethods();
        newMethods.methods.set(method, true);
        return newMethods;
    }

    paymentOptionImage(method: PayMethod) {
        const isOn = this.methods.get(method);

        switch (method) {
            case PayMethod.CREDIT_CARD:
                return isOn ? require("../assets/pay-visa-on.png") : require("../assets/pay-visa-off.png");
            case PayMethod.PAYPAL:
                return isOn ? require("../assets/pay-paypal-on.png") : require("../assets/pay-paypal-off.png");
            case PayMethod.STRIPE:
                return isOn ? require("../assets/pay-stripe-on.png") : require("../assets/pay-stripe-off.png");
            case PayMethod.APPLE_PAY:
                return isOn ? require("../assets/pay-applepay-on.png") : require("../assets/pay-applepay-off.png");
            case PayMethod.PAY_AT_COUNTER:
                return isOn ? require("../assets/pay-payatcounter-on.png") : require("../assets/pay-payatcounter-off.png");
            default:
                return null;
        }
    }

    paymentOptionImageKey(method: PayMethod): number {
        return this.methods.get(method) ? 1 : 2;
    }

    paymentText(method: PayMethod): string {
        switch (method) {
            case PayMethod.CREDIT_CARD:
                return "Pay by Credit Card";
            case PayMethod.PAYPAL:
                return "PayPal";
            case PayMethod.STRIPE:
                return "Stripe";
            case PayMethod.APPLE_PAY:
                return "Apple Pay";
            case PayMethod.PAY_AT_COUNTER:
                return "Pay Later at Counter";
            default:
                return "Unknown";
        }
    }
}

function PayMethodOption({
                             method,
                             methods,
                             onSelect,
                         }: { method: PayMethod, methods: PayMethods, onSelect: (method: PayMethod) => void },
) {
    return (
        <TouchableWithoutFeedback onPress={() => onSelect(method)}>
            <HStack style={methods.paymentOptionStyle(method)}>
                <Text style={methods.paymentOptionTextStyle(method)}>{methods.paymentText(method)}</Text>
                <Image source={methods.paymentOptionImage(method)} alt="visa"
                       key={methods.paymentOptionImageKey(method)} w={120} h={58}/>
            </HStack>
        </TouchableWithoutFeedback>
    );
}

function Payment() {
    const [order, setOrder] = useRecoilState(orderState);
    const [methods, setMethods] = useState(new PayMethods());

    const onSelect = (method: PayMethod) => {
        setMethods(methods.select(method));
        setOrder(order.updatePayMethod(method));
    };

    useEffect(() => {
        let method = PayMethod.PAY_AT_COUNTER;
        //console.log("default: " + JSON.stringify(apps.currentShop));   //${JSON.stringify(yourOrder)}
        if (apps.currentShop.defaultPaymentType == "CREDIT_CARD") {
            method = PayMethod.CREDIT_CARD;
            if (apps.currentShop.qrPaymentMethod == 0) method = PayMethod.PAY_AT_COUNTER;
        } else if (apps.currentShop.defaultPaymentType == "PAY_AT_COUNTER") {
            method = PayMethod.PAY_AT_COUNTER;
            if (apps.currentShop.qrPaymentMethod == 1) method = PayMethod.CREDIT_CARD;
        }
        setMethods(methods.select(method));
        setOrder(order.updatePayMethod(method));
    }, []);

    return (
        <VStack style={{marginTop: 55}}>
            <Box style={styles.paymentMethodBox}>
                <Text style={styles.paymentMethodTitle}>Choose a Payment Method below</Text>
            </Box>

            {/*<PayMethodOption method={PayMethod.PAYPAL} methods={methods} onSelect={onSelect} />
                <PayMethodOption method={PayMethod.CREDIT_CARD} methods={methods} onSelect={onSelect} />
                <PayMethodOption method={PayMethod.STRIPE} methods={methods} onSelect={onSelect} />
                <PayMethodOption method={PayMethod.APPLE_PAY} methods={methods} onSelect={onSelect} />

                // *******************************************
                // apps.currentShop.qrPaymentMethod ?
                // 0: pay at counter
                // 1: credit card
                // 9: All methods
                // *******************************************

            */}

            {(apps.currentShop.qrPaymentMethod == 1 || apps.currentShop.qrPaymentMethod == 9) && (
                <PayMethodOption method={PayMethod.CREDIT_CARD} methods={methods} onSelect={onSelect} />
            )}
            {(apps.currentShop.qrPaymentMethod == 0 || apps.currentShop.qrPaymentMethod == 9) && (
                <PayMethodOption method={PayMethod.PAY_AT_COUNTER} methods={methods} onSelect={onSelect}/>
            )}
        </VStack>
    );
}

const OrderButton: React.FC<{ onOrder: () => void }> = ({onOrder}) => {
    const cart = useRecoilValue(cartState);

    return (
        <Box style={styles.addButtonPane}>
            <TouchableWithoutFeedback onPress={onOrder}>
                <HStack style={styles.addButtonStack}>
                    <Text style={styles.addButtonLabel}>Place Order</Text>
                    <Image source={require("../assets/bracket_HL.png")} alt="lb" h="22px" w="7px"
                           mt="1px" mr="3px"/>
                    <Text style={styles.addButtonPrice}>${cart.total()}</Text>
                    <Image source={require("../assets/bracket_HR.png")} alt="lb" h="22px" w="7px"
                           mt="1px" ml="3px"/>
                </HStack>
            </TouchableWithoutFeedback>
        </Box>
    );
};

const UserDetails: React.FC = () => {
    const [name, setName] = useState<string>(apps.name);
    const [mobile, setMobile] = useState<string>(apps.mobile);

    useEffect(() => {
        console.log(`name: ${name}, mobile: ${mobile}`);
        apps.name = name;
        apps.mobile = mobile;
    }, [name, mobile]);

    return (
        <VStack style={{marginTop: 55}}>
            <Box style={styles.paymentMethodBox}>
                <Text style={styles.paymentMethodTitle}>Your Details</Text>
            </Box>
            <Text style={styles.inputLabel}>Your Name</Text>
            <TextInput style={styles.input} clearTextOnFocus={true}
                       value={name} onChangeText={newName => setName(newName)} maxLength={50}/>
            <Text style={styles.inputLabel}>Your Mobile Number</Text>
            <TextInput style={styles.input} clearTextOnFocus={true} keyboardType="numeric"
                       value={mobile} onChangeText={newMobile => setMobile(newMobile)} maxLength={20}/>
        </VStack>
    );
}

function ItemsAndBottom({onOrder}: { onOrder: () => void }) {
    const cart = useRecoilValue(cartState);

    return (
        <VStack style={{...styles.container, flex: 1}}>
            <Box style={{
                height: 40,
                justifyContent: "center",
                borderBottomColor: "#434343",
                borderBottomWidth: 1,
            }}>
                <Text style={{
                    fontSize: 16,
                    fontWeight: "900",
                    color: "#434343",
                }}>Your Orders</Text>
            </Box>
            <FlatList data={mapToItems(cart.items)} scrollEnabled={true} showsVerticalScrollIndicator={false}
                      ListFooterComponent={
                          <>
                              <Total/>
                              {apps.isTakeaway() && <UserDetails/>}
                              <Payment />
                              <OrderButton onOrder={onOrder}/>
                          </>
                      }

                      renderItem={({item, index}) => {
                          const isLast = index === cart.items.length - 1;

                          return (
                              <VStack style={{width: "100%", backgroundColor: "white"}}>
                                  <HStack style={styles.itemList}>
                                      <VStack style={styles.itemLeft}>
                                          <Text style={styles.itemName}>{item.name}</Text>
                                          <Text style={styles.itemOptions}>{item.optionsDescr()}</Text>
                                      </VStack>
                                      <VStack style={styles.itemRight}>
                                          <Text style={styles.itemPrice}>${item.total.toFixed(1)}</Text>
                                          <Box style={styles.itemQtyBox}>
                                              <Text style={styles.itemQty}>x{item.qty}</Text>
                                          </Box>
                                      </VStack>
                                  </HStack>
                                  {!isLast ?
                                      <DashedLine dashLength={6} dashGap={6} dashColor="#434343"
                                                  style={{height: 1}}/> : null
                                  }
                              </VStack>
                          );
                      }
                      }/>
        </VStack>
    );
}

function Checkout({navigation}) {
    const happyhour: Happyhour = apps.getHappyhour();
    const saleMode: SaleMode = happyhour.saleMode;



    const [cart, setCart] = useRecoilState(cartState);
    const yourOrder = useRecoilValue<YourOrder>(orderState);

    const [orderId, setOrderId] = useState<number>(0);  // order submit했을 때 받는 서버의 order.id
    const [submit, setSubmit] = useState(false);        // submit 진행중
    const [errorMessage, setErrorMessage] = useState("");        // submit 진행중
    const [errorVisible, setErrorVisible] = useState(false);        // submit 진행중
    const [cardPaymentModalVisible, setCardPaymentModalVisible] = useState(false);
    const [paymentUrl, setPaymentUrl] = useState<string>("");     // State for payment URL
    const [paymentSuccess, setPaymentSuccess] = useState(false);
    const [eventData, setEventData] = useState(null); // Store event data here

    // happyhour salemode가 shop을 시작할 때와 달라졌으면 팝업 안내를 표시하고 다시 처음부터 주문을 하게 한다.
    if ((apps.saleMode == SaleMode.HAPPY1 || apps.saleMode == SaleMode.HAPPY2) &&
        saleMode == SaleMode.NORMAL) {

        const happyhourDescr = apps.saleMode == SaleMode.HAPPY1 ? apps.currentShop.happy1Descr : apps.currentShop.happy2Descr;
        //alert(`${happyhourDescr} has ended. Please reorder from the beginning` );
        setErrorMessage(`${happyhourDescr} has ended. Please reorder from the beginning`);
        setErrorVisible(true);
        location.reload();
    }

    const placeOrder = useMutation((order: Order) => remote.placeOrder(order),
        {
            onSuccess: (res) => {
                //setOrderId(res.orderId);
                apps.ordId = res.orderId;
            },
            onError: (err) => {
                // @ts-ignore
                //alert(`err: ${err}`);
                setErrorMessage(`err: ${err}`);
                setErrorVisible(true);

            },
            onMutate: (order) => {
                //console.log("posting: ", JSON.stringify(order, null, 2));
                setSubmit(true);
            },
        });

    // orderId가 0보다 크면 결과를 조회한다.
    const {isLoading, error, data} = useQuery<OrderStatusRes>(
        ["orderStatus"],
        () => remote.getOrderStatus(apps.ordId),
        {
            enabled: apps.ordId > 0,
            retry: (failureCount, error) => failureCount < 7,
            retryDelay: 2000,
            onSuccess: (data: OrderStatusRes) => {

                if (data.orderStatus == "FAILED") {
                    //setOrderId(0);
                    apps.ordIdClear();
                    apps.ordNoClear();
                    setSubmit(false);
                    setCardPaymentModalVisible(false);
                    //alert(data.error);
                    setErrorMessage(data.error);
                    setErrorVisible(true);
                    return;
                }

                if (data.orderStatus == "CANCELED") {
                    //setOrderId(0);
                    apps.ordIdClear();
                    apps.ordNoClear();
                    setSubmit(false);
                    setCardPaymentModalVisible(false);
                    //alert(data.error);
                    return;
                }

                //console.log("onSuccess");
                apps.ordNo = data.ordNo;
                apps.ordId = data.orderId;

                if (yourOrder.payMethod === PayMethod.CREDIT_CARD) {
                    const paymentRefId = apps.currentShop.paymentRefId;
                    const paymentUrl = 'https://pay.urpay.link/' + paymentRefId + '/' + data.orderId + '/' + cart.total();
                    //console.log(paymentUrl);
                    setPaymentUrl(paymentUrl); // Set the payment URL state
                    setSubmit(false);
                    //setOrderId(0);
                    setCardPaymentModalVisible(true);
                    return;
                } else {
                    // Navigate to Ordered page if not using Credit Card
                    setSubmit(false);
                    navigation.navigate("Ordered");
                    return;
                }
            },
            onError: (err: Error) => {
                console.log(`onError: err: ${err}`);
                //setOrderId(0);
                apps.ordIdClear();
                apps.ordNoClear();
                setErrorMessage(err.message);
                setErrorVisible(true);
                setSubmit(false);
                return;
            }
        });

    // useEffect(() => {
    //     console.log(`Your Order: ${JSON.stringify(yourOrder)}`);
    // }, [yourOrder]);

    const onPlaceOrder = () => {
        //console.log("Order placed");
        //console.log(cart);
        //console.log(yourOrder);

        if (yourOrder.payMethod == PayMethod.NOT_SELECTED) {
            // @ts-ignore
            //alert("Please select a payment method");
            setErrorMessage("Please select a payment method");
            setErrorVisible(true);
            return;
        }

        if (apps.isTakeaway() && (apps.name.length < 3 || apps.mobile.length < 10)) {
            //alert("Please enter your name(more than 2 characters) and mobile number");
            setErrorMessage("Please enter your name(more than 2 characters) and mobile number");
            setErrorVisible(true);
            return;
        }

        const order: Order = new Order(apps.currentShop?.id!!, apps.user.id, yourOrder.type,
            yourOrder.qrType, yourOrder.payMethod, yourOrder.tableNo, yourOrder.txId,
            cart.total(),
            cart.items.map(item => new OrderItem(item.menu.id, item.qty, tagOptions(item.options), item.itemPrice, item.saleMode)),
            apps.name, apps.mobile
        );

        localStorage.setItem("name", apps.name);
        localStorage.setItem("mobile", apps.mobile);

        // if (apps.ordId > 0) {
        //     //alert("order is already submitted and confirmed");
        //     //don't place order again if order already has been placed.
        //     //place order -> card payment -> failed -> click pay at counter to place order again.(duplicate)
        //     if (yourOrder.payMethod == PayMethod.CREDIT_CARD) {
        //         // @ts-ignore
        //         //alert("You have an unpaid order. Select 'Pay at Counter' and pay after your meal.");
        //         setCardPaymentModalVisible(true);
        //         return;
        //     } else if (yourOrder.payMethod == PayMethod.PAY_AT_COUNTER) {
        //         //navigation.navigate("Ordered");
        //         //place order -> card payment -> failed -> click pay at counter to place order again.(duplicate)
        //         //ignore the previous order and send it again with Pay At Counter paymethod.
        //         placeOrder.mutate(order);
        //         return;
        //     }
        // } else {
        //     placeOrder.mutate(order);
        // }

        // if (yourOrder.payMethod == PayMethod.CREDIT_CARD) {
        //     setCardPaymentModalVisible(true);
        // }
        placeOrder.mutate(order);
        return;


    };

    // if (error) {
    //     return (<Text>"An error has occurred: " + {error.message}</Text>);
    // }

    const handleCardPaymentSuccess = (receiptData) => {
        //setCardPaymentModalVisible(false);
        //console.log("orderId: ", apps.ordId);

        // Create an instance of the Receipt class
        //const receipt = new Receipt(receiptData["r"], receiptData["postmessage"], receiptData["card_category"], receiptData["card_expiry"], receiptData["card_holder"], receiptData["card_number"], receiptData["card_subcategory"], receiptData["card_type"], receiptData["iframe"], receiptData["show_email"], receiptData["show_extras"], receiptData["token"], receiptData["tokenize_only"], receiptData["v"], receiptData["sca_enabled"], receiptData["merchant"], receiptData["successful"], amount, receiptData["currency"], receiptData["message"], receiptData["reference"], receiptData["id"], receiptData["msg"], receiptData.metadata.original_amount, txTime, apps.currentShop.id, apps.ordId);
        const receipt = new Receipt(
            receiptData["authorization"],
            receiptData["id"],
            receiptData["card_number"],
            receiptData["card_holder"],
            receiptData["card_expiry"],
            receiptData["card_token"],
            receiptData["card_type"],
            receiptData["card_category"],
            receiptData["card_subcategory"],
            receiptData["amount"],
            receiptData["decimal_amount"],
            receiptData["successful"],
            receiptData["message"],
            receiptData["reference"],
            receiptData["currency"],
            receiptData["transaction_id"],
            receiptData["settlement_date"],
            receiptData["transaction_date"],
            receiptData["response_code"],
            receiptData["captured"],
            receiptData["captured_amount"],
            receiptData["rrn"],
            receiptData["cvv_match"],
            receiptData.metadata["original_amount"],
            receiptData.metadata["surcharge_amount"],
            receiptData.metadata["total"],
            receiptData.metadata["surcharge_amount_description"],
            receiptData.metadata["authorization_tracking_id"],  // optional field
            receiptData.metadata["card_sequence_number"],       // optional field
            receiptData.metadata["sca_exemption"],              // optional field
            receiptData.metadata["least_cost_routed"],          // optional field
            receiptData.metadata["original_transaction_reference"], // optional field
            receiptData["serviceId"],                 // optional field
            receiptData["trxSrc"],                    // optional field
            receiptData["success"],
            receiptData["meaning"],
            receiptData["next_steps"],                  // optional field
            apps.currentShop.id,                // shopId
            apps.ordId                         // orderId

        );

        // Log the receipt to the console to see its details
        //console.log(JSON.stringify(receipt, null, 2));

        saveReceiptData.mutate(receipt); // Save the data to the database
        //navigation.navigate("Ordered");

    };

    // Function to save the data to the database
    const saveReceiptData = useMutation((receipt: Receipt) => remote.saveReceipt(receipt),
        {
            onSuccess: (res) => {
                //setOrderId(res.orderId);
                console.log("receipt successfully saved: ", res.receiptId);
            },
            onError: (err) => {
                // @ts-ignore
                //alert(`err: ${err}`);
                setErrorMessage(`err: ${err}`);
                setErrorVisible(true);
            },
            onMutate: (receipt) => {
                //console.log("posting: ", JSON.stringify(receipt, null, 2));

            },
        });

    // Function to cancel the order
    const cancelOrder = useMutation((orderId: number) => remote.cancelOrder(orderId),
        {
            onSuccess: (res) => {
                //setOrderId(res.orderId);
                setCardPaymentModalVisible(false);
                apps.ordIdClear();
                apps.ordNoClear();
                console.log("order cancelled: ", res);
            },
            onError: (err) => {
                // @ts-ignore
                //alert(`err: ${err}`);
                apps.ordIdClear();
                apps.ordNoClear();
                setErrorMessage(`err: ${err}`);
                setErrorVisible(true);

            },
            onMutate: (receipt) => {
                //console.log("posting: ", JSON.stringify(receipt, null, 2));

            },
        });

    const handleCloseModal = () => {
        setCardPaymentModalVisible(false);
        if (eventData && eventData["success"] === true) {
            setPaymentSuccess(true);
            navigation.navigate("Ordered");
            return;
        } else {
            cancelOrder.mutate(apps.ordId);
            setCardPaymentModalVisible(false);
            apps.ordIdClear();
            apps.ordNoClear();
            //alert("Your order has been cancelled. Please place your order again.");
            setErrorMessage("Your order has been cancelled. Please place your order again.");
            setErrorVisible(true);
            //navigation.navigate("Ordered");
            return;
        }

    };

    const { width, height } = Dimensions.get('window'); // Get the screen width and height

    useEffect(() => {

        let navigated = false; // Flag to check if navigation has already occurred
        const messageHandler = (event: MessageEvent) => {
            //console.log("event.origin: " + event.origin);
            const eventData = JSON.stringify(event.data, null, 2);
            //console.log("event.data: " + eventData);

            if (event.origin !== "https://pay.urpay.link") {
                return;
            }

            if (event.data["success"] != undefined) {
                if (event.data["success"] === true) {
                    handleCardPaymentSuccess(event.data.result);
                    setEventData(event.data.result); // Save event data
                } else {
                    // Handle payment failure
                   // alert("Payment failed. Please try again or close this window to complete your payment at the counter.");
                    setErrorMessage("Payment failed. Please try again or try using a different card.");
                    setErrorVisible(true);
                }
            }

            //여기에 모든 로그를 남기도록 하자.

            //Next 버튼을 눌렀을때, 현재 iframe 을 없애고, Ordered 페이지로 이동한다.
            if (event.data["action"] == "success" && !navigated) {
                navigated = true; // Set the flag to true after navigation
                setCardPaymentModalVisible(false);
                setPaymentSuccess(true);
                navigation.navigate("Ordered");
            }
        };

        window.addEventListener('message', messageHandler);

        return () => {
            window.removeEventListener('message', messageHandler);
        };
    }, []);


    return (
        <VStack style={{flex: 1, width: "100%", backgroundColor: "white"}}>
            <Title navigation={navigation}/>
            <Table/>
            <Header shop={apps.currentShop!!}/>
            <ItemsAndBottom onOrder={onPlaceOrder}/>
            {submit && <SubmitIndicator/>}
            {!paymentSuccess && (
            <Modal isOpen={cardPaymentModalVisible} onClose={handleCloseModal} closeOnOverlayClick={false}>
                <Modal.Content width="90vw" height="90vh" maxWidth="90vw" maxHeight="90vh">
                    <Modal.CloseButton />
                    <Modal.Header>Card Payment</Modal.Header>
                    <Modal.Body>
                        {
                            <iframe
                                id="paymentIframe"
                                src={paymentUrl}
                                title="Card Payment"
                                style={{ width: '80vw', height: '75vh', borderWidth: '0px' }}
                            />
                        }
                    </Modal.Body>
                </Modal.Content>
            </Modal>
            )}
            <Modal isOpen={errorVisible} onClose={() => setErrorVisible(false)}>
                <Modal.Content>
                    <Modal.Header>{`${apps.currentShop!!.name} Says`}</Modal.Header>
                    <Modal.Body>
                        <Text>{errorMessage}</Text>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button style={{width: "100px"}} onPress={() => setErrorVisible(false)}> OK </Button>
                    </Modal.Footer>
                </Modal.Content>
            </Modal>
        </VStack>
    );
}

const styles = StyleSheet.create({
    container: {
        marginTop: 15,
        marginLeft: 20,
        marginRight: 20,
    }, headerTop: {
        borderBottomColor: "#434343",
        borderBottomWidth: 2,
    }, shopName: {
        fontSize: 16,
        fontWeight: "900",
        color: "#434343",
        marginBottom: 4,
        letterSpacing: -0.4,
    }, showAddress: {
        fontSize: 12,
        color: "#434343",
        marginBottom: 9,
        letterSpacing: -0.3,
    }, headerBottom: {
        borderBottomColor: "#434343",
        borderBottomWidth: 1,
        height: 54,
        alignItems: "flex-end",
        paddingBottom: 8,
    }, pickupTime: {
        fontSize: 13,
        color: "#434343",
        letterSpacing: -0.33,
    }, pickupTimeLabel: {
        fontSize: 13,
        fontWeight: "bold",
        color: "#434343",
        letterSpacing: -0.33,
        flex: 1,
    }, itemList: {
        width: "100%", backgroundColor: "white", height: 59,
        // borderBottomWidth: 1, borderBottomColor: "#434343",
        paddingTop: 6,
    }, itemLeft: {
        flex: 1, justifyContent: "center",
    }, itemName: {
        fontSize: 14,
        fontWeight: "bold",
        letterSpacing: -0.3,
        color: "#434343",
    }, itemOptions: {
        fontSize: 12,
        letterSpacing: -0.3,
        color: "#999",
    }, itemRight: {
        justifyContent: "flex-start", alignItems: "flex-end",
    }, itemPrice: {
        fontSize: 16,
        color: "#434343",
        letterSpacing: -0.3,
    }, itemQtyBox: {
        marginTop: 3,
        borderRadius: 9,
        borderWidth: 0,
        backgroundColor: "#999999",
        paddingHorizontal: 5,
        paddingVertical: 0,
        width: 25,
        height: 20,
        alignItems: "center",
        justifyContent: "center",
    }, itemQty: {
        marginTop: -2,
        fontSize: 12,
        fontWeight: "bold",
        color: "white",
    }, addButtonPane: {
        width: "100%",
        height: 150,
    }, addButtonStack: {
        backgroundColor: "#db146d",
        borderRadius: 20,
        margin: 20,
        height: 58,
        marginLeft: 0,
        marginRight: 0,
        alignItems: "center",
        padding: 20,
    }, addButtonLabel: {
        flex: 1, fontSize: 18, fontWeight: "bold", color: "white",
    }, addButtonPrice: {
        fontSize: 18, fontWeight: "bold", color: "white",
    }, totalContainer: {
        backgroundColor: "white",
    }, totalRow: {
        height: 39, width: "100%",
        borderTopWidth: 1, borderTopColor: "#434343", alignItems: "center",
    }, totalLabel: {
        flex: 1, justifyContent: "center",
        fontSize: 16, fontWeight: "900", color: "#434343", letterSpacing: -0.4,
    }, totalPrice: {
        justifyContent: "center", alignItems: "flex-end", fontSize: 16, letterSpacing: -0.3,
    }, totalRowLast: {
        height: 39, width: "100%",
        borderTopWidth: 1, borderTopColor: "#434343", alignItems: "center",
        borderBottomWidth: 1, borderBottomColor: "#434343",
    }, titleContainer: {
        height: 49,
        backgroundColor: "white",
        justifyContent: "flex-start",
        alignItems: "center",
        marginLeft: 20,
    }, title: {
        fontSize: 22,
        fontWeight: "900",
        color: "#434343",
        letterSpacing: -0.55,
        lineHeight: 35,
    }, paymentOption: {
        height: 58, borderColor: "#bcaf9a", borderWidth: 1,
        borderRadius: 20, marginTop: 10,
        alignItems: "center", justifyContent: "space-between",
        paddingLeft: 20, paddingRight: 0,
    }, paymentOptionSelected: {
        height: 58, borderColor: "#db146d", borderWidth: 1,
        borderRadius: 20, marginTop: 10,
        alignItems: "center", justifyContent: "space-between",
        paddingLeft: 20, paddingRight: 0,
    }, paymentOptionText: {
        color: "#bcaf9a", fontSize: 16, fontWeight: "bold", letterSpacing: -0.4,
    }, paymentOptionTextSelected: {
        color: "#db146d", fontSize: 16, fontWeight: "bold", letterSpacing: -0.4,
    }, paymentMethodBox: {
        height: 30, borderBottomColor: "#bcaf9a", borderBottomWidth: 1,
        marginBottom: 5,
    }, paymentMethodTitle: {
        color: "#db146d", fontSize: 16, fontWeight: "900", letterSpacing: -0.4,
    }, tableContainer: {
        height: 71, width: "100%", backgroundColor: "#f7f5f2",
        borderTopWidth: 1, borderTopColor: "#dbd3c6", alignItems: "center",
        justifyContent: "flex-end",
    }, tableText: {
        fontSize: 16, letterSpacing: -0.4, color: "#db146d", fontWeight: "900",
    }, titleBack: {
        height: 26, width: 26,
        marginTop: 5, marginRight: 10,
    }, input: {
        height: 38, borderWidth: 0.5, padding: 10, fontSize: 14,
        fontWeight: "500", letterSpacing: -0.4, color: "#555", marginBottom: 10,
        borderTopColor: "#999", borderLeftColor: "#999", borderRightColor: "#999", borderBottomColor: "#999",
        borderRadius: 5, backgroundColor: "#f7f5f2",
    },
    inputLabel: {
        fontSize: 14, fontWeight: "500", letterSpacing: -0.4, color: "#777", padding: 5,
    },
});

export default Checkout;
